import { Link } from 'components/link/Link';
import { Logo } from 'components/logo/Logo';

import style from './HomeButton.module.scss';

export type HomeButtonType = {};
export const HomeButton = (props: HomeButtonType): JSX.Element => {
  return (
    <Link href="/">
      <a className={style.logo}>
        <Logo />
      </a>
    </Link>
  );
};
