// https://stackoverflow.com/questions/8188548/splitting-a-js-array-into-n-arrays
export const splitToChunks = (arrayRaw, parts) => {
  const result = [];
  const array = [...arrayRaw];
  for (let i = parts; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)));
  }
  return result;
};

export const getOrientation = (w, h) => {
  if (w == h) return `square`;
  if (w > h) return `landscape`;
  if (w < h) return `portrait`;
};

export const isBrowser = () => typeof window !== 'undefined';

export const getAPI = () => {
  return process.env.NEXT_PUBLIC_API;
};

export const getData = async (absUrl, context = {}) => {
  const { preview, params } = context;
  if (preview) {
    absUrl = `${absUrl}?preview=${process.env.PREVIEW_KEY}`;
  }
  const res = await fetch(absUrl);
  return res.json();
};

export const getEndpoint = async (path, context) => {
  return getData(`${getAPI()}${path}`, context);
};

export const isMobileWidth = (w) => {
  if (!w && typeof window !== 'undefined') {
    return window.innerWidth <= 768;
  }
  return w <= 768;
};

export const hasTouch = () => document.documentElement.classList.contains('touchevents');

export const isSafari = () => {
  return (
    navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') == -1
  );
};

export const setCSSVar = (varName, value) => {
  if (!value) debugger;
  document.documentElement.style.setProperty(varName, value);
};
export const getCSSVar = (varName) => {
  return parseFloat(getComputedStyle(document.documentElement).getPropertyValue(varName));
};

export const isIOS = () => {
  const ua = navigator.userAgent;
  const ios =
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  return ios;
};
