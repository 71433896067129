import typogr from 'typogr';

export const widont = (html) => {
  // wrap all co-p or co-partnership
  // this is strange, as either tinymce or browsers swap out a 
  // hyphen for a non-breaking hyphen, if the word is longer
  // than 'x' chars
  const regex = /(Co[-]P|Co[-]Partnership)/gm;
  const subst = '<span style="white-space:nowrap;">$1</span>';
  html = html.replace(regex, subst);

  return typogr(html).widont();
};
