import { CSSTransition } from 'react-transition-group';
import styles from './PageTransition.module.scss';

export const PageTransition = ({
  node,
  animation = 'fade',
  style,
  in: inProp = false,
  onEntered,
  onExited,
}) => {
  let variable = '0';
  if (typeof window !== 'undefined') {
    //  should be in MS!!!!
    variable = getComputedStyle(document.documentElement).getPropertyValue('--pageTransitionTime');
  }
  const time = parseFloat(variable);
  return (
    <CSSTransition
      in={inProp}
      onEntered={onEntered}
      onExited={onExited}
      classNames={{
        enter: styles.enter,
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exit: styles.exit,
        exitActive: styles.exitActive,
        exitDone: styles.exitDone,
      }}
      timeout={time}
    >
      <div className={styles[animation]}>{node}</div>
    </CSSTransition>
  );
};
