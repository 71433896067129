import { useScrollDirection } from 'hooks/useScroll';
import React, { useEffect, useState } from 'react';
import { isMobileWidth } from 'utils/helpers';
import style from './StickyScrollMenu.module.scss';

export type StickyScrollMenuType = { children?: React.ReactNode };
export const StickyScrollMenu = ({ children }: StickyScrollMenuType): JSX.Element => {
  const [isDownThePageEnough, setisDownThePageEnough] = useState<boolean>(false);

  const scroll = useScrollDirection(200);

  useEffect(() => {
    const onScroll = () => {
      const isDownThePageEnough =
        typeof window !== 'undefined'
          ? window.scrollY > window.innerHeight / 2
            ? true
            : false
          : false;
      setisDownThePageEnough(isDownThePageEnough);
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [setisDownThePageEnough]);

  const containerState = scroll === 'up' && isDownThePageEnough ? style.containerIsVisible : null;
  return <div className={`${style.container} ${containerState}`}>{children}</div>;
};
