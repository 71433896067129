import { widont } from 'utils/typography';
import style from './ConnectBlockSubscribe.module.scss';

export type ConnectBlockTaglineType = {
  intro: string;
};
export const ConnectBlockTagline = ({ intro }: ConnectBlockTaglineType): JSX.Element => {
  return (
    <div
      className={style.paragraph}
      dangerouslySetInnerHTML={{ __html: widont(intro) }}
    ></div>
  );
};
