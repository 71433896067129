import { createContext, useContext } from 'react';

export type ThemeContextType = {
  theme: 'dark' | 'light';
  setTheme: (theme: 'dark' | 'light') => void;
};

export const ThemeContext = createContext<ThemeContextType>({
  theme: 'dark',
  setTheme: () => {},
});

export const useThemeContext = (): ThemeContextType => useContext(ThemeContext);
