// Page transitions are done with react-transition-group

import { RouterScrollProvider } from '@moxy/next-router-scroll';
import { MainNav } from 'components/mainNav/MainNav';
import { IntroAnimation } from 'components/introAnimation/IntroAnimation';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { AppInner } from '../components/general/appInner/AppInner';
import { ThemeContext } from '../context/ThemeContext';
import { IntroContext } from '../context/IntroContext';

import { pageview } from '../utils/google';
import { setCSSVar } from '../utils/helpers';
// polyfills
import 'core-js/actual/string/replace-all';

import '../scss/global.scss';

export const TIMEOUT = 400;

function MyApp(props) {
  const router = useRouter();
  const [theme, setTheme] = useState('dark');
  const [introHtml, setIntroHtml] = useState('');
  const [showIntro, setShowIntro] = useState(true);

  const handleRouteChange = (url) => {
    pageview(url);
  };
  const onShowIntroComplete = () => {
    setShowIntro(false);
  };

  const onSetTheme = (theme) => {
    setTheme(theme);
  };

  const themeValues = { theme, setTheme: onSetTheme };
  const introValues = { html: introHtml, setHtml: setIntroHtml };

  useEffect(() => {
    document.fonts.ready.then(function () {
      document.body.classList.add('is-fonts-loaded');
    }, []);
  }, []);

  useEffect(() => {
    const resize = () => {
      setCSSVar('--vw', window.innerWidth + 'px');
    };
    window.addEventListener('resize', resize);
    resize();
  }, []);

  useEffect(() => {
    if (showIntro) {
      document.body.classList.add('is-scroll-disabled');
    } else {
      document.body.classList.remove('is-scroll-disabled');
    }
  }, [showIntro]);
  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <ThemeContext.Provider value={themeValues}>
      <div className={theme}>
        <Head>
          <meta property="og:type" content="website" />
          <meta property="og:image" content="/images/co-partnership-opengraph.jpg" key="image" />
          <meta
            property="og:url"
            content={`${process.env.NEXT_PUBLIC_HOST}${router.asPath}`}
            key="url"
          />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <IntroContext.Provider value={introValues}>
          {showIntro && <IntroAnimation onComplete={onShowIntroComplete} />}
          <MainNav />
          <RouterScrollProvider>
            <AppInner {...props} />
          </RouterScrollProvider>
        </IntroContext.Provider>
      </div>
    </ThemeContext.Provider>
  );
}

export default MyApp;
