import { Link } from 'components/link/Link';
import style from './ConnectBlockSubscribe.module.scss';

export type ConnectBlockSubscribeType = {
  pinterestLink: string;
  instagramLink: string;
  facebookLink: string;
  linkedinLink: string;
};
export const ConnectBlockSubscribe = ({
  pinterestLink,
  instagramLink,
  facebookLink,
  linkedinLink,
}: ConnectBlockSubscribeType): JSX.Element => {
  return (
    <>
      <p className={style.paragraph}>
        <a className={style.link} href={instagramLink}>
          Instagram
        </a>
      </p>
      <p className={style.paragraph}>
        <a className={style.link} href={linkedinLink}>
          LinkedIn
        </a>
      </p>
    </>
  );
};
