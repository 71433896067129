import { useRouterScroll } from '@moxy/next-router-scroll';
import PageSwapper from '@moxy/react-page-swapper';
import { PageTransition } from 'components/pageTransition/PageTransition';
import { Analytics } from 'components/analytics/Analytics';

export const AppInner = ({ Component, pageProps }) => {
  const { updateScroll } = useRouterScroll();
  const {generic} = pageProps;
  const {globalData} = generic ? generic : pageProps;

  return (
    <>
      <Analytics html={globalData.analytics} />
      <PageSwapper updateScroll={updateScroll} node={<Component {...pageProps} />}>
        {(props) => <PageTransition {...props} />}
      </PageSwapper>
    </>
  );
};
