import { createContext, useContext } from 'react';

export type IntroContextType = {
  html: string;
  setHtml: (html: string) => void;
};

export const IntroContext = createContext<IntroContextType>({
  html: '',
  setHtml: () => {},
});

export const useIntroContext = (): IntroContextType => useContext(IntroContext);
