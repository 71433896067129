import React, { useState } from 'react';
import Modal from 'react-modal';

import { Link } from 'components/link/Link';
import style from './Hamburger.module.scss';

export type HamburgerType = {
  onClick: Function;
  isOpen: Boolean;
};

export const Hamburger = (props: HamburgerType): JSX.Element => {
  const lineStyle = props.isOpen ? style.lineActive : '';
  const containerState = props.isOpen ? style.containerActive : '';
  const onClick = () => {
    props.onClick();
  };
  return (
    <div className={`${style.container} ${containerState}`} onClick={onClick}>
      <span className={`${style.line} ${lineStyle}`}></span>
      <span className={`${style.line} ${lineStyle}`}></span>
    </div>
  );
};
