import { Logo } from 'components/logo/Logo';
import { StandardIntro } from 'components/standardIntro/StandardIntro';
import { useIntroContext } from 'context/IntroContext';
import { gsap } from 'gsap/dist/gsap';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import style from './IntroAnimation.module.scss';

export type IntroAnimationType = { onComplete: () => void };

export const IntroAnimation = ({ onComplete }: IntroAnimationType): JSX.Element => {
  const { html } = useIntroContext();
  useEffect(() => {
    const timeline = gsap.timeline();

    const hasSeen = Cookies.get('hasSeenIntro');
    if (hasSeen) {
      onComplete();
    } else {
      timeline.to(['.logo', '.text'], { y: 0, alpha: 1, delay: 1 });
      timeline.to(['.logo'], { alpha: 0, delay: 3 });
      timeline.to(['.text'], { alpha: 0 });
      timeline.to(['.intro'], {
        alpha: 0,
        delay: 0.5,
        onComplete: () => {
          Cookies.set('hasSeenIntro', 'yes', { expires: 30 });
          onComplete();
        },
      });
    }
  }, []);
  return (
    <div className={`${style.container} intro`}>
      <div className={`${style.intro} text`}>
        <StandardIntro
          intro={{
            html: html,
          }}
        />
      </div>
      <div className={`${style.logoContainer} logo`}>
        <Logo forceLarge={true} />
      </div>
    </div>
  );
};
