import parse, { DOMNode, Element, domToReact } from 'html-react-parser';
import Script from 'next/script';


type ReplaceReturnType = JSX.Element | object | void | undefined | null | false;

export interface CmsHtmlType {
  html: string;
  replace?: (domNode: DOMNode) => ReplaceReturnType;
}

const parser = ({ html }: CmsHtmlType) =>
  parse(html, {
    replace: (domNode: DOMNode): ReplaceReturnType => {
      if (domNode instanceof Element) {
        if (domNode.attribs.class === 'remove') {
          return <></>;
        }

        if (domNode.type === 'script') {
          return <Script {...domNode.attribs}>{domToReact(domNode.children)}</Script>;
        }
      }
    },
  });

export interface AnalyticsType {
  html: string;
}

export const Analytics = (props: AnalyticsType): JSX.Element => {
  const reactEls = parser(props);
  return <>{reactEls}</>;
};