import { GenericPageLayoutProps } from 'components/general/genericPageLayout/GenericPageLayout';
import { ParallaxListItem } from 'components/parallaxList/ParallaxList';
import { ProjectType } from 'pages/project/[id]';
import { getEndpoint } from './helpers';

export const parseProjectsToParallaxList = (
  featuredProjects: ProjectType[],
): ParallaxListItem[] => {
  return featuredProjects.map((project, index) => {
    return {
      id: project.slug,
      type: project.categories.map((cat) => cat.name).join(', '),
      title: project.full_name,
      color: project.theme_color || '#000000',
      img: {
        image: project.hero.image,
        image_width: project.hero.image_width,
        image_height: project.hero.image_height,
        alt_text: '',
      },
    };
  });
};

export const parseGenericPage = async (
  path: string,
  context: object,
  selectorClass?: string,
): Promise<GenericPageLayoutProps> => {
  const page = await getEndpoint(path, (context = {}));
  const projects = await getEndpoint('/projects/', context);

  const featuredProjects = projects.filter((project) => project.is_featured);
  const parallaxList: ParallaxListItem[] = parseProjectsToParallaxList(featuredProjects);

  const globalData = await getGlobalData();

  const props: GenericPageLayoutProps = {
    blocks: page.blocks,
    meta_title: page.meta_title,
    meta_description: page.meta_description,
    meta_image: page.meta_image,
    title: page.title,
    heading: page.heading,
    intro: page.intro || '',
    connectTaglineHtml: page.contact_blurb,
    parallaxList,
    blockStyles: selectorClass || null,
    children: null,
    originalResponse: page,
    globalData,
  };

  return props;
};

export type GlobalDataType = {
  social: { facebook: string; instagram: string; pintrest: string; linkedin: string };
  contact: { addressHtml: string; emailHtml: string; blurbHtml: string };
  intro: { html: string };
  analytics: string;
};

export const getGlobalData = async (): Promise<GlobalDataType> => {
  const contactJSON = await getEndpoint('/contact-details/');
  const socialJSON = await getEndpoint('/social-media/');
  const introJSON = await getEndpoint('/loading-screen/');
  const metaJSON = await getEndpoint('/site-metadata/');

  const addressHtml = contactJSON.address;
  const emailHtml = contactJSON.contact;
  const blurbHtml = contactJSON.contact_blurb;

  return {
    social: {
      facebook: socialJSON.facebook || '',
      instagram: socialJSON.instagram || '',
      pintrest: socialJSON.pintrest || '',
      linkedin: socialJSON.linkedin || '',
    },
    contact: { addressHtml, emailHtml, blurbHtml },
    intro: { html: introJSON.text },
    analytics: metaJSON.analytics
  };
};
