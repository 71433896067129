import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap/dist/gsap';

import { Link } from 'components/link/Link';
import style from './Menu.module.scss';
import { ConnectBlockSubscribe } from 'components/connectBlocks/ConnectBlockSubscribe';
import { ConnectBlockTagline } from 'components/connectBlocks/ConnectBlockTagline';
import { ConnectBlockEmail } from 'components/connectBlocks/ConnectBlockEmail';
import { useRouter } from 'next/router';

export type MenuType = {
  resetScroll: () => void;
  isOpen: Boolean;
  closeTimeoutMS: number;
  onShowComplete;
  addressHtml: string;
  emailHtml: string;
  blurbHtml: string;
  social: { pinterest: string; instagram: string; facebook: string; linkedin: string };
};

export const Menu = ({
  isOpen,
  closeTimeoutMS,
  onShowComplete,
  addressHtml,
  emailHtml,
  blurbHtml,
  social,
  resetScroll,
}: MenuType): JSX.Element => {
  const bg = useRef<HTMLDivElement>(null);
  const list = useRef<HTMLUListElement>(null);
  const footer = useRef<HTMLDivElement>(null);

  const openTimeline = gsap.timeline();
  const closeTimeline = gsap.timeline();

  const closingDuration = closeTimeoutMS / 1000;

  useEffect(() => {
    if (isOpen) {
      if (bg.current) {
        gsap.killTweensOf(bg.current);
        gsap.set(bg.current, { alpha: 0, transformOrigin: '100%' });
        openTimeline.to(bg.current, {
          alpha: 1,
          duration: 0.25,
          ease: 'power4.inOut',
          onComplete: () => {
            onShowComplete();
          },
        });
      }
      if (list.current) {
        gsap.utils.toArray(list.current.children).forEach((element: any, index) => {
          gsap.killTweensOf(element);
          gsap.set(element, { opacity: 0 });
          openTimeline.to(element, { opacity: 1, duration: 0.25 }, '<25%');
        });
      }
      if (footer.current) {
        gsap.utils.toArray(footer.current.children).forEach((element: any, index) => {
          gsap.killTweensOf(element);
          gsap.set(element, { opacity: 0 });
          openTimeline.to(element, { opacity: 1, duration: 0.15 }, '<');
        });
      }
    } else {
      // closing animation
      if (list.current) {
        gsap.utils.toArray(list.current.children).forEach((element: any, index) => {
          gsap.killTweensOf(element);
          closeTimeline.to(element, { opacity: 0, duration: 0.1 }, '<25%');
        });
      }
      if (footer.current) {
        gsap.utils.toArray(footer.current.children).forEach((element: any, index) => {
          gsap.killTweensOf(element);
          closeTimeline.to(element, { opacity: 0, duration: 0.1 }, '<%');
        });
      }
      if (bg.current) {
        gsap.killTweensOf(bg.current);
        closeTimeline.to(bg.current, {
          onStart: () => {
            resetScroll();
          },
          alpha: 0,
          duration: 0.15,
          ease: 'power4.inOut',
        });
      }

      closeTimeline.duration(closingDuration);
      closeTimeline.play();
    }
  });

  const { asPath } = useRouter();

  const links = [
    { href: '/work', label: 'Work' },
    { href: '/about', label: 'About' },
    { href: '/culture', label: 'Culture' },
    { href: '/careers', label: 'Careers' },
  ];

  return (
    <div className={style.menuContainer}>
      <div ref={bg} className={style.bg}></div>

      <ul ref={list} className={style.list}>
        {links.map((link) => {
          const itemState = link.href === asPath ? style.listItemLinkActive : null;
          return (
            <li className={`${style.listItem} `} key={link.label}>
              <Link href={link.href}>
                <a className={`${style.listItemLink} ${itemState}`}>{link.label}</a>
              </Link>
            </li>
          );
        })}

        {/* <li className={style.listItem}>
          <Link href="/contact">
            <a className={style.listItemLink}>Contact</a>
          </Link>
        </li> */}
      </ul>
      <div ref={footer} className={style.footer}>
        <div className={style.tagLine}>
          <ConnectBlockTagline intro={blurbHtml} />
        </div>
        <div className={style.email}>{<ConnectBlockEmail html={emailHtml} />}</div>
        <div className={style.address}>{<ConnectBlockEmail html={addressHtml} />}</div>
        <div className={style.subscribe}>
          <ConnectBlockSubscribe
            facebookLink={social.facebook}
            pinterestLink={social.pinterest}
            instagramLink={social.instagram}
            linkedinLink={social.linkedin}
          />
        </div>

        <div className={style.copyright}>
          Co-Partnership. © {new Date().getFullYear()} All Rights Reserved.
          <br />
          <Link href="/privacy">
            <a className={style.copyrightLink}>Privacy Policy</a>
          </Link>
          .{' '}
          <Link href="/terms">
            <a className={style.copyrightLink}>Terms of Use</a>
          </Link>
        </div>
      </div>
    </div>
  );
};
