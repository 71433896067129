import { Link } from 'components/link/Link';
import { Logo } from 'components/logo/Logo';
import { PageIntroText, PageIntroTextType } from 'components/pageIntroText/PageIntroText';
import { ImageType } from 'pages/project/[id]';
import style from './StandardIntro.module.scss';

export type StandardIntroType = {
  intro: PageIntroTextType;
};
export const StandardIntro = ({ intro }: StandardIntroType): JSX.Element => {
  return (
    <div className={style.container}>
      <PageIntroText {...intro} />
    </div>
  );
};
