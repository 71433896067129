import style from './Logo.module.scss';
import SVGDesktop from './logo.svg';
import SVGMobile from './logo-mobile.svg';

export type LogoType = { forceLarge?: boolean };
export const Logo = ({ forceLarge = false }: LogoType): JSX.Element => {
  return (
    <div className={style.container}>
      {forceLarge ? (
        <SVGDesktop className={style.forcedLarge} />
      ) : (
        <>
          <SVGDesktop className={style.desktop} />
          <SVGMobile className={style.mobile} />
        </>
      )}
    </div>
  );
};
