import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

import style from './MainNav.module.scss';
import { Hamburger } from 'components/hamburger/Hamburger';
import { Menu } from './Menu';
import { useRouter } from 'next/router';
import { HomeButton } from 'components/homeButton/HomeButton';
import { getGlobalData } from 'utils/parsers';
import { StickyScrollMenu } from 'components/stickyScrollMenu/StickyScrollMenu';
import { isMobileWidth } from 'utils/helpers';
import gsap from 'gsap/dist/gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);
export type MainNavType = {};

Modal.setAppElement('#__next');

export const MainNav = (props: MainNavType): JSX.Element => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [lastScroll, setLastScroll] = useState<number>(0);
  const router = useRouter();

  const [addressHtml, setAddressHtml] = useState<string>('');
  const [emailHtml, setEmailHtml] = useState<string>('');
  const [blurbHtml, setBlurbHtml] = useState<string>('');
  const [social, setSocial] = useState<{
    pinterest: string;
    instagram: string;
    linkedin: string;
    facebook: string;
  }>({
    pinterest: '',
    instagram: '',
    linkedin: '',
    facebook: '',
  });

  useEffect(() => {
    (async () => {
      const data = await getGlobalData();

      setEmailHtml(data.contact.emailHtml);
      setAddressHtml(data.contact.addressHtml);
      setBlurbHtml(data.contact.blurbHtml);
      setSocial({
        pinterest: data.social.pintrest,
        instagram: data.social.instagram,
        linkedin: data.social.linkedin,
        facebook: data.social.facebook,
      });
    })();
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      setLastScroll(0);
      closeModal();
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);
  useEffect(() => {
    const onScroll = () => {
      // js-sticky-home-button
      const isHome = router.asPath === '/';
      const isOnPhone = isMobileWidth(window.innerWidth);
      if (isOnPhone) return;

      if (isHome && window.scrollY < window.innerHeight * 1.2) {
        gsap.to('.js-sticky-home-button', { autoAlpha: 0, duration: 0.1 });
      } else {
        gsap.to('.js-sticky-home-button', { autoAlpha: 1 });
      }
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.addEventListener('scroll', onScroll);
    };
  }, [router.asPath]);

  const openMenu = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen(!modalIsOpen);
  };

  const onModalOpen = () => {
    setLastScroll(window.scrollY);
  };

  const onShowComplete = () => {
    document.body.classList.add('u-no-body-scroll');
  };

  const onHide = () => {
    document.body.classList.remove('u-no-body-scroll');
    gsap.to(window, { duration: 0, scrollTo: lastScroll });
  };
  const onModalClose = () => {
    // window.scrollTo(0, lastScroll);
  };
  const customStyles = {
    content: {
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      // right: '0',
      // bottom: '0',
      marginRight: '0',
      padding: 0,
      border: 0,
      borderRadius: 0,
      backgroundColor: 'rgba(0,0,0,0)',
      zIndex: 100,
    },
  };
  const isHome = router.asPath === '/';
  const logoState = isHome ? '' : style.logoContainerShow;
  const logoColour = modalIsOpen ? style.logoIsWhite : null;
  return (
    <>
      <StickyScrollMenu>
        <div className={style.stickyScrollContainer}>
          <div className={style.stickyScrollToggleContainer}>
            <Hamburger onClick={toggleMenu} isOpen={modalIsOpen} />
          </div>
          <div className={`${style.stickyScrollLogoContainer} ${logoState} `}>
            <div className={`${style.stickyLogo} js-sticky-home-button`}>
              <HomeButton />
            </div>
          </div>
        </div>
      </StickyScrollMenu>
      <div className={style.container}>
        <div className={style.toggleContainer}>
          <Hamburger onClick={toggleMenu} isOpen={modalIsOpen} />
        </div>
        <div className={`${style.logoContainer} ${logoState} ${logoColour}`}>
          <HomeButton />
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Menu overlay"
          shouldCloseOnOverlayClick={false}
          onAfterOpen={onModalOpen}
          onAfterClose={onModalClose}
          overlayClassName={style.overlay}
          closeTimeoutMS={750}
        >
          <Menu
            isOpen={modalIsOpen}
            closeTimeoutMS={750}
            addressHtml={addressHtml}
            blurbHtml={blurbHtml}
            emailHtml={emailHtml}
            social={social}
            onShowComplete={onShowComplete}
            resetScroll={onHide}
          />
        </Modal>
      </div>
    </>
  );
};
